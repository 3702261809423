<template>
  <form style="width: 800px; max-width: 100%" @submit.prevent="save" v-if="personas.length">
    <h3 class="card-title">Cargar permiso de parte</h3>
    <div class="cols2">
      <suggest
        id="empleado"
        label="Empleado"
        placeholder="Buscar empleado.."
        :value="empleado"
        :data="personas"
        :items="personaList"
        @selected="empleadoSelected"
        :required="enviado && !empleado"
      ></suggest>

      <div class="form-group">
        <label class="form-label">Puede crear/editar</label>
        <select
          id="publicado"
          class="form-control"
          v-model="permiso.can_create"
        >
          <option :value="1">Si</option>
          <option :value="0">No</option>
        </select>
      </div>
    </div>
<!--    <div class="cols2" style="align-items: flex-end">-->
<!--      <suggest-->
<!--        id="area"-->
<!--        label="Buscar área"-->
<!--        placeholder="Buscar área.."-->
<!--        :data="areas"-->
<!--        :items="AreaServices.dataList()"-->
<!--        @selected="isAreaSelected"-->
<!--        :required="enviado && !areaSelected"-->
<!--      ></suggest>-->
<!--      <button class="btn btn-primary">Agregar área</button>-->
<!--    </div>-->
<!--    <div class="w-100" style="padding: .5rem">-->
<!--      <table class="table-list">-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th>Area</th>-->
<!--          <th></th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        <tr v-for="areaSeleccionada in areasSeleccionadas" :key="areaSeleccionada.id">-->
<!--          <td>-->
<!--            {{ areaSeleccionada.descripcion }}-->
<!--          </td>-->
<!--          <td>-->
<!--            <button-->
<!--              class="btn btn-error btn-sm"-->
<!--              style="height: 40px; width: 42px; min-width: 42px;"-->
<!--              type="button"-->
<!--              @click="deleteAreaSeleccionada(areaSeleccionada)">-->
<!--              <icon feather="trash" style="height: 20px; width: 20px;"/>-->
<!--            </button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </div>-->
    <button class="btn btn-primary mt-2" type="submit">Guardar</button>
  </form>
</template>
<script>
import {PersonasService} from '../../personas/services/PersonasServices';
import Suggest from '@/components/elements/Suggest';
import PermisoParteService from '../services/PermisoParteService';
import Swal from 'sweetalert2';
import {AreaServices} from "../../areas/services/AreaServices";

export default {
  name: 'EditPermiso',
  computed: {
    AreaServices() {
      return AreaServices
    }
  },
  components: {
    Suggest,
  },
  props: {
    permisoToEdit: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      enviado: false,
      permiso: {
        can_create: 0,
        persona_id: null,
      },
      empleado: null,
      personaList: PersonasService.dataList(),
      personas: [],
      areas: [],
      areasSeleccionadas: [],
      areaSelected: null
    };
  },
  async mounted() {
    this.permiso = Object.assign({}, this.permisoToEdit)
    this.permiso.areas_habilitadas = JSON.parse(this.permisoToEdit.areas_habilitadas)
    this.personas = await PersonasService.api.getAll()
    this.areas = await AreaServices.api.getAllForGuardias()
    this.permiso.areas_habilitadas.forEach((area_habilitada) => {
      const areaSeleccionada = this.areas.find((area) => {
        return area.id === area_habilitada
      })
      if (areaSeleccionada) {
        this.areasSeleccionadas.push(areaSeleccionada)
      }
    })
    this.empleado = this.personas.find(persona => persona.id === this.permiso.persona_id)
  },
  methods: {
    save() {
      this.enviado = true;
      if (!this.permiso.persona_id) {
        return;
      }
      PermisoParteService.api
        .edit(this.permiso)
        .then(() => {
          this.$emit('saved');
          Swal.fire('Éxito', `<p>Se guardó con éxito.</p>`, 'success');
        })
        .catch((e) => {
          console.log(e)
          Swal.fire('Error', `<p>${e}</p>`, 'error');
        });
    },
    isAreaSelected(area) {
      if (area) {
        this.areaSelected = area;
      }
    },
    deleteAreaSeleccionada(area) {
      this.areasSeleccionadas = this.areasSeleccionadas.filter((areaSeleccionada) => areaSeleccionada.id !== area.id)
      this.permiso.areas_habilitadas = this.permiso.areas_habilitadas.filter(area_habilitada => area_habilitada !== area.id)
    }
  },
};
</script>
