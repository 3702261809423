import axios from "axios"

export default {
    api: {
        async getAll(){
            return axios.get('permiso-parte')
            .then((response) => { return response.data })
            .catch((error) => { throw error })
        },
        async add(permisoParte) {
            return axios.post('permiso-parte', permisoParte)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async edit(permisoParte) {
            return axios.put('permiso-parte/'+permisoParte.id, permisoParte)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async delete(permisoParteId) {
            return axios.delete('permiso-parte/'+permisoParteId)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        }
    }
}